@use "./styles/colors";
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap");

$lineheight: 19px;

html {
  background-color: colors.$bgr;
  color: colors.$fgr;
  font-family: "Fira Code", monospace;
}

body {
  margin: 0;
  display: flex;
  justify-content: center;
  line-height: $lineheight;
  width: 100vw;
}

.root {
  width: 100vw;
}

.container {
  width: 80vw;
  margin-bottom: 20px;
}

.tbox {
  border: 3.25px solid colors.$fgr;
  padding-left: 4ch;
  padding-right: 4ch;
  margin-top: $lineheight;

  .legend-holder {
    padding: 0 1ch;
    //height: 24px;
    margin-top: -10px;
  }

  .legend {
    background: colors.$bgr none repeat scroll 0 0;
    display: inline-block;
    margin-bottom: 0px;
    margin-left: -1ch;
    padding: 0 1ch;
  }
}
.inner {
  display: flex;
  flex-direction: column;
  text-wrap: wrap;
  flex-wrap: wrap;

  div {
    flex-wrap: wrap;

    span {
      cursor: pointer;
    }
  }
}

.firstBreak {
  height: $lineheight - 9px;
}

.selected {
  height: $lineheight;
}

.selected.pink {
  background-color: colors.$pnk;
  color: colors.$bgr;
  font-weight: bold;
}

.selected.purple {
  background-color: colors.$pur;
  color: colors.$fgr;
}

.selected.cyan {
  background-color: colors.$cyn;
  color: colors.$bgr;
}

.mr {
  margin-right: 1ch;
}

i {
  width: 2ch;
}

.hint {
  color: colors.$com;
}

.green {
  color: colors.$grn;
}

.purple {
  color: colors.$pur;
}

.pink {
  color: colors.$pnk;
}

.cyan {
  color: colors.$cyn;
}

.highlight {
  background-color: colors.$fgr;
  color: colors.$bgr;
}

.header {
  white-space: pre;
  overflow: hidden;
  display: flex;
  justify-content: start;
  animation: textShadow 1.6s infinite;
}

.cursor {
  content: "|";
  animation: cursor 1s infinite step-start;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}

.cursor.hidden {
  display: none;
}

.split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  flex-wrap: wrap-reverse;
  gap: 0 10ch;
}

@keyframes textShadow {
  0% {
    text-shadow: 0.4389924193300864px 0 1px rgba(0, 30, 255, 0.5),
      -0.4389924193300864px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  5% {
    text-shadow: 2.7928974010788217px 0 1px rgba(0, 30, 255, 0.5),
      -2.7928974010788217px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  10% {
    text-shadow: 0.02956275843481219px 0 1px rgba(0, 30, 255, 0.5),
      -0.02956275843481219px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  15% {
    text-shadow: 0.40218538552878136px 0 1px rgba(0, 30, 255, 0.5),
      -0.40218538552878136px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  20% {
    text-shadow: 3.4794037899852017px 0 1px rgba(0, 30, 255, 0.5),
      -3.4794037899852017px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  25% {
    text-shadow: 1.6125630401149584px 0 1px rgba(0, 30, 255, 0.5),
      -1.6125630401149584px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  30% {
    text-shadow: 0.7015590085143956px 0 1px rgba(0, 30, 255, 0.5),
      -0.7015590085143956px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  35% {
    text-shadow: 3.896914047650351px 0 1px rgba(0, 30, 255, 0.5),
      -3.896914047650351px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  40% {
    text-shadow: 3.870905614848819px 0 1px rgba(0, 30, 255, 0.5),
      -3.870905614848819px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  45% {
    text-shadow: 2.231056963361899px 0 1px rgba(0, 30, 255, 0.5),
      -2.231056963361899px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  50% {
    text-shadow: 0.08084290417898504px 0 1px rgba(0, 30, 255, 0.5),
      -0.08084290417898504px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  55% {
    text-shadow: 2.3758461067427543px 0 1px rgba(0, 30, 255, 0.5),
      -2.3758461067427543px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  60% {
    text-shadow: 2.202193051050636px 0 1px rgba(0, 30, 255, 0.5),
      -2.202193051050636px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  65% {
    text-shadow: 2.8638780614874975px 0 1px rgba(0, 30, 255, 0.5),
      -2.8638780614874975px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  70% {
    text-shadow: 0.48874025155497314px 0 1px rgba(0, 30, 255, 0.5),
      -0.48874025155497314px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  75% {
    text-shadow: 1.8948491305757957px 0 1px rgba(0, 30, 255, 0.5),
      -1.8948491305757957px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  80% {
    text-shadow: 0.0833037308038857px 0 1px rgba(0, 30, 255, 0.5),
      -0.0833037308038857px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  85% {
    text-shadow: 0.09769827255241735px 0 1px rgba(0, 30, 255, 0.5),
      -0.09769827255241735px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  90% {
    text-shadow: 3.443339761481782px 0 1px rgba(0, 30, 255, 0.5),
      -3.443339761481782px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  95% {
    text-shadow: 2.1841838852799786px 0 1px rgba(0, 30, 255, 0.5),
      -2.1841838852799786px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
  100% {
    text-shadow: 2.6208764473832513px 0 1px rgba(0, 30, 255, 0.5),
      -2.6208764473832513px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
  }
}
