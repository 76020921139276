// Dracula Color Scheme
// Main Background Color
$bgr: #282A36;
// Main Foreground Color
$fgr: #F8F8F2;
$sel: #44475A;
$com: #6272A4;
$red: #FF5555;
$org: #FFB86C;
$yel: #F1FA8C;
$grn: #50FA7B;
$pur: #BD93F9;
$cyn: #8BE9FD;
$pnk: #FF79C6;

